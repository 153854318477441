import { useState } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import Button from '@mui/material/Button';
import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import Image from 'src/components/image';
import { MotionContainer, varFade } from 'src/components/animate';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';
// section
import HeroSkeleton from 'src/sections/home/home-hero-skeleton';
// utils
import { getImgUrl } from 'src/utils/loadImgsFromS3';
// paths
import { paths } from 'src/routes/paths';
// theme
import { bgGradient } from 'src/theme/css';
// ----------------------------------------------------------------------

export default function HomeLandingHero({ products, isLoading, ...other }) {
  const theme = useTheme();

  const carousel = useCarousel({
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...CarouselDots({
      rounded: true,
      sx: {
        left: 0,
        right: 0,
        zIndex: 9,
        bottom: 40,
        height: 30,
        mx: 'auto',
      },
    }),
  });

  return (
    <Container
      sx={{
        mt: 5,
      }}
    >
      {isLoading ? (
        <HeroSkeleton />
      ) : (
        <Box
          sx={{
            ...bgGradient({
              color: alpha(theme.palette.background.default, 0.7),
              imgUrl: '/assets/background/overlay_4.jpg',
            }),
            borderRadius: 3,
            overflow: 'hidden',
            position: 'relative',
            pb: products.length === 1 && 2,
          }}
        >
          <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
            {products.map((product, index) => (
              <CarouselItem
                key={product._id}
                customKey={product._id}
                product={product}
                active={index === carousel.currentIndex}
              />
            ))}
          </Carousel>
        </Box>
      )}
    </Container>
  );
}

HomeLandingHero.propTypes = {
  products: PropTypes.array,
  isLoading: PropTypes.bool,
};

// ----------------------------------------------------------------------

function CarouselItem({ product, active, customKey }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const theme = useTheme();

  const { label = 'Featured Product', name, description, cover, _id } = product;

  const linkTo = paths.product.details(_id);

  const renderImg = (
    <Grid
      xs={12}
      md={6}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!isLoaded && (
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '8px',
            ml: 'auto',
            mr: { xs: 'auto', md: 'unset' },
            backgroundColor: 'transparent',
            width: { xs: 300, sm: 400 },
            height: { xs: 300, sm: 400 },
          }}
        />
      )}

      <Image
        src={getImgUrl(cover, 'medium')}
        afterLoad={() => {
          setIsLoaded(true);
        }}
        sx={{
          borderRadius: '8px',
          filter: `drop-shadow(20px 20px 24px ${alpha(theme.palette.common.black, 0.16)})`,
          width: { xs: 300, sm: 400 },
          height: { xs: 300, sm: 400 },
          objectFit: 'cover',
          ml: 'auto',
          mr: { xs: 'auto', md: 'unset' },
        }}
      />
    </Grid>
  );

  return (
    <MotionContainer action animate={active} sx={{ position: 'relative' }}>
      <Grid
        key={customKey}
        container
        spacing={2}
        rowSpacing={{
          xs: 5,
          md: 0,
        }}
        sx={{
          pt: 5,
          px: { xs: 3, md: 10 },
          display: active ? 'flex' : 'none',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        <Grid xs={12} md={6}>
          <Box
            sx={{
              maxWidth: { md: 440 },
              textAlign: { xs: 'center', md: 'unset' },
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: { xs: 'center', md: 'flex-start' },
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <m.div variants={varFade().inRight}>
              <Label color="warning">{label}</Label>
            </m.div>

            <m.div variants={varFade().inRight}>
              <TextMaxLine line={1} variant="h3">
                {name}
              </TextMaxLine>
            </m.div>

            <m.div variants={varFade().inRight}>
              <TextMaxLine
                line={2}
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  height: '50px',
                }}
              >
                {description.replace(/<\/?[^>]+(>|$)/g, '')}
              </TextMaxLine>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Button
                component={RouterLink}
                href={linkTo}
                size="large"
                color="inherit"
                variant="contained"
                endIcon={<Iconify icon="carbon:chevron-right" />}
                sx={{
                  alignSelf: { xs: 'center', md: 'flex-start' },
                  mt: 5,
                }}
              >
                View Details
              </Button>
            </m.div>
          </Box>
        </Grid>

        {renderImg}
      </Grid>
    </MotionContainer>
  );
}

CarouselItem.propTypes = {
  active: PropTypes.bool,
  product: PropTypes.object,
  customKey: PropTypes.string,
};
