import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

// route
import { RouterLink } from 'src/routes/components';
import { CATEGORIES } from '../product/filters/subCatFilers/filtersConfig';

// ----------------------------------------------------------------------

const defaultFilters = {
  filterRegion: [],
  filterCity: '',
  filterSubCategory: '',
  filterCategory: '',
  filterRating: 0,
  filterPriceRange: [0, 0],
  filterStock: false,
  filterSearch: '',
  sortBy: 'featured',
  coordinates: null,
};

const generateCategoryUrl = (categoryLabel) => {
  const queryParams = new URLSearchParams({
    ...defaultFilters,
    filterCategory: categoryLabel,
  });

  return `/product/category?${queryParams.toString()}`;
};

export default function HomeCategories({ categoryCounts = {} }) {
  return (
    <Container
      sx={{
        py: { xs: 5, md: 8 },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
          mb: 2,
        }}
      >
        Categories
      </Typography>

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(4, 1fr)',
          md: 'repeat(6, 1fr)',
        }}
      >
        {CATEGORIES.map((cat) => (
          <Stack
            key={cat.label}
            alignItems="center"
            justifyContent="center"
            sx={{
              px: 1,
              py: 3,
              borderRadius: 2,
              cursor: 'pointer',
              border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
              '&:hover': {
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
              },
            }}
          >
            <Box
              sx={{
                mb: 2,
                bgcolor: 'background.neutral',
                borderRadius: '50%',
              }}
            >
              <Link component={RouterLink} to={generateCategoryUrl(cat.category)} underline="none">
                <Image
                  src={cat.img}
                  sx={{ width: 100, height: 100 }}
                  alt={cat.alt}
                  lazyLoad={false}
                />
              </Link>
            </Box>
            <Link component={RouterLink} to={generateCategoryUrl(cat.category)} underline="none">
              <TextMaxLine variant="subtitle2" line={1}>
                {`${cat.label} (${categoryCounts && categoryCounts[cat?.category]})`}
              </TextMaxLine>
            </Link>
          </Stack>
        ))}
      </Box>
    </Container>
  );
}

HomeCategories.propTypes = {
  categoryCounts: PropTypes.object,
};
