import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Autocomplete from '@mui/material/Autocomplete';

import Iconify from 'src/components/iconify/iconify';

const defaultFilters = {
  filterRegion: [],
  filterCity: '',
  filterSubCategory: '',
  filterCategory: '',
  filterRating: null,
  filterPriceRange: [0, 0],
  filterStock: false,
  filterSearch: 'dress',
  sortBy: 'featured',
  coordinates: null,
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.3,
  findAllMatches: false,
  minMatchCharLength: 2,
  location: 0,
  distance: 100,
  ignoreLocation: false,
  isCaseSensitive: false,
  keys: [],
};

export default function UniversalSearchBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [error, setError] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [fuse, setFuse] = useState(null); // To hold the Fuse.js instance

  // Lazy load the autocomplete terms when the user starts typing
  useEffect(() => {
    if (searchText.trim() && !fuse) {
      // Dynamically import the JSON file when the user starts typing
      import('src/utils/autocompleteTerms.json')
        .then((module) => {
          const autocompleteTerms = module.default; // Access the terms from the JSON
          const fuseInstance = new Fuse(autocompleteTerms, fuseOptions);
          setFuse(fuseInstance);
        })
        .catch((err) => {
          console.error('Error loading autocomplete terms:', err);
        });
    }
  }, [searchText, fuse]);

  useEffect(() => {
    if (searchText.trim() && fuse) {
      const words = searchText.trim().split(' ');
      const lastWord = words[words.length - 1];

      const results = fuse
        .search(lastWord)
        .map((result) => result.item)
        .slice(0, 10);

      setFilteredOptions(results);
    } else {
      setOpen(false);
      setFilteredOptions([]);
    }

    if (searchText.endsWith(' ')) {
      setOpen(false);
    }
  }, [searchText, fuse]);

  const handleOptionSelect = (event, value, reason) => {
    if (value) {
      let updatedText = '';

      if (searchText.trim().length === 0) {
        updatedText = `${value} `;
      } else {
        const words = searchText.trim().split(' ');
        words[words.length - 1] = value;
        updatedText = `${words.join(' ')} `;
      }
      setSearchText(updatedText);
    }
  };

  const getQuery = () => {
    const queryParams = new URLSearchParams();

    Object.keys(defaultFilters).forEach((key) => {
      if (defaultFilters[key] != null) {
        queryParams.set(key, defaultFilters[key]);
      }
    });

    if (searchText) {
      queryParams.set('filterSearch', searchText);
    }

    queryParams.set('page', 1);
    queryParams.set('limit', 20);

    return queryParams;
  };

  const handleSubmit = () => {
    if (searchText === '') {
      setError(true);
      return;
    }

    setOpen(false);
    const queryParams = getQuery();
    navigate(`/product/search?${queryParams.toString()}`);
  };

  const handleSearchChange = (event, newInputValue) => {
    setSearchText(newInputValue);
    setOpen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 12,
        mx: isMobile ? 2 : 4,
      }}
    >
      <Box
        sx={{
          maxWidth: isMobile ? '100%' : '700px',
          width: '100%',
          position: 'relative',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <Autocomplete
          fullWidth
          freeSolo
          open={open}
          id="combo-box-demo"
          value={searchText}
          options={filteredOptions}
          filterOptions={(options) => options}
          isOptionEqualToValue={(option, value) => value === '' || option === value}
          noOptionsText={searchText ? 'No products found.' : ''}
          onInputChange={handleSearchChange}
          onChange={handleOptionSelect}
          sx={{
            '& .MuiOutlinedInput-root': {
              paddingRight: '10px!important',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={error}
              name="searchField"
              variant="outlined"
              placeholder="Search for products..."
              onKeyDown={handleKeyPress}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Iconify width={25} icon="material-symbols:search" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Button
          sx={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
}
