import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

function HeroSkeleton() {
  return (
    <Box
      sx={{
        my: 5,
        borderRadius: 3,
        overflow: 'hidden',
        position: 'relative',
        padding: { xs: 3, md: 10 },
        background: 'rgba(0, 0, 0, 0.05)',
        maxWidth: 1200,
      }}
    >
      <Grid container spacing={2}>
        {/* Text Section */}
        <Grid xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Skeleton variant="text" width="50%" height={30} />
            <Skeleton variant="text" width="70%" height={40} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="90%" height={20} />
            <Skeleton variant="rectangular" width={150} height={50} sx={{ mt: 3 }} />
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid xs={12} md={6}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={400}
            sx={{
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HeroSkeleton;
