import fashionImg from 'src/assets/categories/fashion.webp';
import homeImg from 'src/assets/categories/homeAndFurniture.webp';
import vehicleImg from 'src/assets/categories/car.webp';
import vehiclePartsImg from 'src/assets/categories/vehicleParts.webp';
import realEstateImg from 'src/assets/categories/realEstate.webp';
import electronicsImg from 'src/assets/categories/electronics.webp';
import mobileTabletImg from 'src/assets/categories/mobileTablet.webp';
import healthBeautyImg from 'src/assets/categories/healthBeauty.webp';
import babyKidsImg from 'src/assets/categories/babyKids.webp';
import foodImg from 'src/assets/categories/food.webp';
import gemstonesImg from 'src/assets/categories/gemstones.webp';
import servicesImg from 'src/assets/categories/services.webp';

export const LABEL_TO_GROUP = {
  fashion: 'fashion',
  vehicle: 'vehicle',
  'vehicle parts': 'vehicleParts',
  'real estate': 'realEstate',
  electronics: 'electronics',
  'mobile & tablets': 'mobileAndTablets',
  'health & beauty': 'healthAndBeauty',
  'baby & kids': 'babyAndKids',
  food: 'food',
  gemstones: 'gemstones',
  'home & furniture': 'homeAndFurniture',
};

export const GROUP_TO_LABEL = {
  fashion: 'fashion',
  vehicle: 'vehicle',
  vehicleParts: 'vehicle parts',
  realEstate: 'real estate',
  electronics: 'electronics',
  mobileAndTablets: 'mobile & tablets',
  healthAndBeauty: 'health & beauty',
  babyAndKids: 'baby & kids',
  food: 'food',
  gemstones: 'gemstones',
};

export const CATEGORIES = [
  {
    id: 1,
    category: 'fashion',
    label: 'Fashion',
    icon: '/assets/icons/ecommerce/ic_fashion.svg',
    img: fashionImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/fashion.webp',
    path: '#',
  },
  {
    id: 2,
    category: 'vehicle',
    label: 'Vehicle',
    icon: '/assets/icons/ecommerce/ic_vehicle.png',
    img: vehicleImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/car.webp',
    path: '#',
  },
  {
    id: 3,
    category: 'vehicleParts',
    label: 'Vehicle Parts',
    icon: '/assets/icons/ecommerce/ic_vehicleparts.png',
    img: vehiclePartsImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/vehicleParts.webp',
    path: '#',
  },
  {
    id: 4,
    category: 'realEstate',
    label: 'Real estate',
    img: realEstateImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/realEstate.webp',
    path: '#',
  },
  {
    id: 5,
    category: 'electronics',
    label: 'Electronics',
    img: electronicsImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/electronics.webp',
    path: '#',
  },
  {
    id: 6,
    category: 'mobileAndTablets',
    label: 'Mobile & Tablets',
    img: mobileTabletImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/mobileTablet.webp',
    path: '#',
  },
  {
    id: 7,
    category: 'healthAndBeauty',
    label: 'Health & Beauty',
    img: healthBeautyImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/healthBeauty.webp',
    path: '#',
  },
  {
    id: 8,
    category: 'babyAndKids',
    label: 'Baby & Kids',
    img: babyKidsImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/babyKids.webp',
    path: '#',
  },
  {
    id: 9,
    category: 'food',
    label: 'Food',
    img: foodImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/food.webp',
    path: '#',
  },
  {
    id: 10,
    category: 'gemstones',
    label: 'Gemstones',
    img: gemstonesImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/gemstones.webp',
    path: '#',
  },
  {
    id: 11,
    category: 'homeAndFurniture',
    label: 'Home & Furniture',
    img: homeImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/homeAndFurniture.webp',
    path: '#',
  },
  {
    id: 12,
    category: 'services',
    label: 'Services',
    img: servicesImg,
    alt: 'https://dy6l8mnlnom4l.cloudfront.net/categories/services.webp',
    path: '#',
  },
];

export const CATEGORY_OPTIONS = {
  fashion: ['clothing', 'accessories', 'jewelry', 'shoes', 'bag', 'wedding'],
  vehicle: ['car', 'bus', 'motor', 'truck'],
  vehicleParts: [
    'engine',
    'engineOil',
    'filter',
    'brakes',
    'interior',
    'lights',
    'wheels',
    'tires',
    'gearbox',
    'suspension',
    'exhaust',
    'battery',
    'others',
  ],
  realEstate: ['apartment', 'house', 'land', 'commercial'],
  electronics: ['computer', 'monitor', 'sound', 'camera', 'home', 'others'],
  mobileAndTablets: ['mobile', 'tablet'],
  healthAndBeauty: ['fragrance', 'skincare', 'makeup', 'hair', 'oralcare'],
  babyAndKids: ['accessories', 'clothing', 'childcare', 'shoes', 'toys'],
  food: [
    'fruitAndvegetables',
    'dairyAndEggs',
    'basicFood',
    'drinks',
    'livestock',
    'snacksAndsweets',
    'others',
  ],
  gemstones: [
    'diamond',
    'ruby',
    'sapphire',
    'emerald',
    'pearl',
    'opal',
    'amethyst',
    'tourmaline',
    'topaze',
    'garnet',
    'peridot',
    'aquamarine',
    'others',
  ],
};

// ---------------- FASHION

export const GENDER_OPTION = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' },
  { label: 'Unisex', value: 'Unisex' },
];

export const TYPE_OPTIONS_CLOTHING = {
  clothing: {
    type: [
      'jeans',
      'trousers',
      't-shirt',
      'jacket',
      'sportswear',
      'traditional',
      'underwear',
      'pajamas',
      'fabrics',
      'jumper',
      'dresses',
      'abayas',
      'pants',
      'tops',
    ],
    material: ['cotton', 'polyester', 'wool', 'silk', 'others'],
    size: ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', 'others'],
    color: ['black', 'white', 'blue', 'gray', 'red', 'green', 'brown', 'navy', 'beige', 'pink'],
  },
};

export const clothingComponent = [
  {
    id: 1,
    name: 'fashion.clothing.type',
    label: 'Type*',
    options: TYPE_OPTIONS_CLOTHING.clothing.type,
  },
  {
    id: 2,
    name: 'fashion.clothing.material',
    label: 'Material',
    options: TYPE_OPTIONS_CLOTHING.clothing.material,
  },
  {
    id: 3,
    name: 'fashion.clothing.size',
    label: 'Size',
    options: TYPE_OPTIONS_CLOTHING.clothing.size,
  },
  {
    id: 4,
    name: 'fashion.clothing.color',
    label: 'Color',
    options: TYPE_OPTIONS_CLOTHING.clothing.color,
  },
];

// ACCESSORIES

export const TYPE_OPTIONS_ACCESSORIES = [
  'sunglasses',
  'belts',
  'ties',
  'watches',
  'hats & caps',
  'scarves',
  'hijabs',
  'pants',
  'tops',
  'hair accessories',
  'others',
];

export const accessoriesComponent = [
  {
    id: 1,
    name: 'fashion.accessories.type',
    label: 'Type',
    options: TYPE_OPTIONS_ACCESSORIES,
  },
];

// JEWELRY

export const TYPE_OPTIONS_JEWELRY = ['rings', 'watches', 'bracelets', 'necklaces', 'earings'];

export const jewelryComponent = [
  {
    id: 1,
    name: 'fashion.jewelry.type',
    label: 'Type*',
    options: TYPE_OPTIONS_JEWELRY,
  },
];

// SHOES

export const TYPE_OPTIONS_SHOES = [
  'slippers',
  'sneakers',
  'formal shoes',
  'casual shoes',
  'boots',
  'sandals',
  'sport shoes',
];

export const SIZE_OPTIONS_SHOES = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  'others',
];

export const shoesComponent = [
  {
    id: 1,
    name: 'fashion.shoes.type',
    label: 'Type*',
    options: TYPE_OPTIONS_SHOES,
  },
  {
    id: 2,
    name: 'fashion.shoes.size',
    label: 'Size*',
    options: SIZE_OPTIONS_SHOES,
  },
];

// BAG

export const TYPE_OPTIONS_BAG = [
  'backpack',
  'ballets',
  'laptop bags',
  'sports bag',
  'luggage',
  'others',
];

export const bagComponent = [
  {
    id: 1,
    name: 'fashion.bag.type',
    label: 'Type*',
    options: TYPE_OPTIONS_BAG,
  },
];

// WEDDING

export const TYPE_OPTIONS_WEDDING = ['bride', 'suits', 'bridesmaid', 'children'];

export const weddingComponent = [
  {
    id: 1,
    name: 'fashion.wedding.type',
    label: 'Type*',
    options: TYPE_OPTIONS_WEDDING,
  },
];

// ---------------- VEHICLE

// CAR

const VEHICLE_CAR_MAKE = [
  'toyota',
  'nissan',
  'mitsubishi',
  'mercedes',
  'land rover',
  'volkswagen',
  'hyundai',
  'suzuki',
  'others',
];

const VEHICLE_YEAR = [
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
];

const VEHICLE_TRANSMISSION = ['automatic', 'manual', 'other'];

export const carComponent = [
  {
    id: 1,
    name: 'vehicle.car.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicle.car.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicle.car.transmission',
    label: 'transmission*',
    options: VEHICLE_TRANSMISSION,
  },
  {
    id: 4,
    name: 'vehicle.car.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 5,
    name: 'vehicle.car.condition',
    label: 'Condition*',
    options: ['New', 'Used'],
  },
  {
    id: 6,
    name: 'vehicle.car.fuel',
    label: 'Fuel*',
    options: ['Petrol', 'Diesel', 'Gas', 'Other'],
  },
];

// BUS

export const busComponent = [
  {
    id: 1,
    name: 'vehicle.bus.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicle.bus.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicle.bus.transmission',
    label: 'transmission*',
    options: VEHICLE_TRANSMISSION,
  },
  {
    id: 4,
    name: 'vehicle.bus.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 5,
    name: 'vehicle.bus.condition',
    label: 'Condition*',
    options: ['New', 'Used'],
  },
  {
    id: 6,
    name: 'vehicle.bus.fuel',
    label: 'Fuel*',
    options: ['Petrol', 'Diesel', 'Gas', 'Other'],
  },
];

// MOTOR

const VEHICLE_MOTOR_MAKE = [
  'toyota',
  'nissan',
  'mitsubishi',
  'mercedes',
  'higer',
  'yutong',
  'hyundai',
  'scania',
  'others',
];

export const motorComponent = [
  {
    id: 1,
    name: 'vehicle.motor.make',
    label: 'Make*',
    options: VEHICLE_MOTOR_MAKE,
  },
  {
    id: 2,
    name: 'vehicle.motor.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicle.motor.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicle.motor.fuel',
    label: 'Fuel*',
    options: ['Petrol', 'Diesel', 'Gas', 'Other'],
  },
];

// TRUCK

const VEHICLE_TRUCK_TYPE = [
  'excavators',
  'concrete mixer',
  'dumpers',
  'mini truck',
  'truck',
  'trailers',
  'farm machines',
  'others',
];

export const truckComponent = [
  {
    id: 1,
    name: 'vehicle.truck.type',
    label: 'Type*',
    options: VEHICLE_TRUCK_TYPE,
  },
  {
    id: 2,
    name: 'vehicle.truck.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 3,
    name: 'vehicle.truck.condition',
    label: 'Condition*',
    options: ['New', 'Used'],
  },
  {
    id: 4,
    name: 'vehicle.truck.fuel',
    label: 'Fuel*',
    options: ['Petrol', 'Diesel', 'Gas', 'Other'],
  },
];

// ---------------- VEHICLE PARTS

const VEHICLE_PARTS_CONDITION = ['New', 'Used'];

const VEHICLE_PARTS_MAKE = [
  'toyota',
  'nissan',
  'mitsubishi',
  'ford',
  'chevrolet',
  'mercedes',
  'bmw',
  'land rover',
  'volkswagen',
  'hyundai',
  'suzuki',
  'others',
];

const BATTERY_MAKE = [
  'ACDelco',
  'Duralast Gold',
  'Duralast Platinum',
  'Duralast',
  'Econocraft',
  'Odyssey Battery',
  'Optima',
  'Valucraft',
  'Bosch',
  'Duracell',
  'Interstate',
  'Napa',
  'Others',
];

const ENGINEOIL_VISCOSITY = [
  '0W-16',
  '0W-20',
  '0W-30',
  '0W-40',
  '5W-20',
  '5W-30',
  '5W-40',
  '5W-50',
  '10W-30',
  '10W-40',
  '15W-40',
  '10W-50',
  '10W-60',
  '20W-50',
];
const BATTERY_TYPE = ['lead-acid', 'lithium-ion', 'AGM', 'others'];

const BRAKE_TYPE = ['disk', 'drum', 'others'];

const SUSPENSION_TYPE = ['coil', 'leaf', 'air', 'others'];

const FILTER_TYPE = ['oil', 'air', 'fuel', 'cabin air', 'transmission', 'others'];

const WHEEL_MATERIAL = ['alloy', 'steel'];

const GEARBOX_TYPE = ['manual', 'automatic', 'semi-automatic', 'others'];

const ENGINE_OIL_TYPE = ['synthetic', 'semi-synthetic', 'mineral'];

const LIGHT_TYPE = ['headlights', 'taillights', 'interior', 'fog', 'others'];

const LIGHT_TECHNOLOGY = ['LED', 'halogen', 'xenon', 'others'];

const INTERIOR_PART = ['seat', 'steering', 'dashboard', 'mat', 'others'];

const BATTERY_VOLTAGES = ['6V', '12V'];

export const engineComponent = [
  {
    id: 1,
    name: 'vehicleParts.engine.make',
    label: 'Make*',
    options: VEHICLE_PARTS_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.engine.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.engine.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.engine.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const batteryComponent = [
  {
    id: 1,
    name: 'vehicleParts.battery.make',
    label: 'Make*',
    options: BATTERY_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.battery.type',
    label: 'Type',
    options: BATTERY_TYPE,
  },
  {
    id: 3,
    name: 'vehicleParts.battery.voltage',
    label: 'Voltage*',
    options: BATTERY_VOLTAGES,
  },
  {
    id: 4,
    name: 'vehicleParts.battery.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const brakesComponent = [
  {
    id: 1,
    name: 'vehicleParts.brakes.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.brakes.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.brakes.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.brakes.type',
    label: 'Type*',
    options: BRAKE_TYPE,
  },
  {
    id: 5,
    name: 'vehicleParts.brakes.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const suspensionComponent = [
  {
    id: 1,
    name: 'vehicleParts.suspension.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.suspension.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts..suspension.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts..suspension.type',
    label: 'Type',
    options: SUSPENSION_TYPE,
  },
  {
    id: 5,
    name: 'vehicleParts.suspension.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const filterComponent = [
  {
    id: 1,
    name: 'vehicleParts.filter.type',
    label: 'Type*',
    options: FILTER_TYPE,
  },
  {
    id: 2,
    name: 'vehicleParts.filter.make',
    label: 'Make*',
    options: VEHICLE_PARTS_MAKE,
  },
  {
    id: 3,
    name: 'vehicleParts.filter.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 4,
    name: 'vehicleParts.filter.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
];

export const gearboxComponent = [
  {
    id: 1,
    name: 'vehicleParts.gearbox.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.gearbox.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.gearbox.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.gearbox.type',
    label: 'Type',
    options: GEARBOX_TYPE,
  },
  {
    id: 5,
    name: 'vehicleParts.gearbox.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const exhaustComponent = [
  {
    id: 1,
    name: 'vehicleParts.exhaust.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.exhaust.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.exhaust.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.exhaust.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const lightsComponent = [
  {
    id: 1,
    name: 'vehicleParts.lights.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.lights.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.lights.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.lights.type',
    label: 'Type*',
    options: LIGHT_TYPE,
  },
  {
    id: 5,
    name: 'vehicleParts.lights.technology',
    label: 'Technology',
    options: LIGHT_TECHNOLOGY,
  },
  {
    id: 6,
    name: 'vehicleParts.lights.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const interiorComponent = [
  {
    id: 1,
    name: 'vehicleParts.interior.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.interior.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.interior.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.interior.part',
    label: 'Part*',
    options: INTERIOR_PART,
  },
  {
    id: 5,
    name: 'vehicleParts.interior.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const wheelsComponent = [
  {
    id: 1,
    name: 'vehicleParts.wheels.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.wheels.model',
    label: 'Model',
    options: [],
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.wheels.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.wheels.material',
    label: 'Material',
    options: WHEEL_MATERIAL,
  },
  {
    id: 5,
    name: 'vehicleParts.wheels.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const tiresComponent = [
  {
    id: 1,
    name: 'vehicleParts.tires.make',
    label: 'Make*',
    options: VEHICLE_CAR_MAKE,
  },
  {
    id: 2,
    name: 'vehicleParts.tires.model',
    label: 'Model',
    options: VEHICLE_CAR_MAKE,
    freeSolo: true,
  },
  {
    id: 3,
    name: 'vehicleParts.tires.year',
    label: 'Year*',
    options: VEHICLE_YEAR,
  },
  {
    id: 4,
    name: 'vehicleParts.tires.condition',
    label: 'Condition*',
    options: VEHICLE_PARTS_CONDITION,
  },
];

export const engineOilComponent = [
  {
    id: 32,
    name: 'vehicleParts.engineOil.type',
    label: 'Type*',
    options: ENGINE_OIL_TYPE,
  },
  {
    id: 33,
    name: 'vehicleParts.engineOil.viscosity',
    label: 'Viscosity*',
    options: ENGINEOIL_VISCOSITY,
  },
];

// ---------------- REAL ESTATE

export const REAL_ESTATE_LISTING = ['rent', 'sale'];
export const REAL_ESTATE_FURNISHED = [true, false];

export const apartmentComponent = [
  {
    id: 1,
    name: 'realEstate.apartment.bedrooms',
    label: 'Bedrooms*',
    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    id: 2,
    name: 'realEstate.apartment.bathRooms',
    label: 'Bathrooms*',
    options: ['0', '1', '2', '3', '4', '5'],
  },
  {
    id: 3,
    name: 'realEstate.apartment.listing',
    label: 'Listing*',
    options: REAL_ESTATE_LISTING,
  },
  // {
  //   id: 4,
  //   name: 'realEstate.apartment.furnished',
  //   label: 'Furnished*',
  //   options: REAL_ESTATE_FURNISHED,
  // },
];

export const houseComponent = [
  {
    id: 1,
    name: 'realEstate.house.bedrooms',
    label: 'Bedrooms*',
    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    id: 2,
    name: 'realEstate.house.bathrooms',
    label: 'Bathrooms*',
    options: ['0', '1', '2', '3', '4', '5'],
  },
  {
    id: 3,
    name: 'realEstate.house.listing',
    label: 'Listing*',
    options: REAL_ESTATE_LISTING,
  },
  // {
  //   id: 4,
  //   name: 'realEstate.house.furnished',
  //   label: 'Furnished*',
  //   options: REAL_ESTATE_FURNISHED,
  // },
];

export const commercialComponent = [
  // {
  //   id: 1,
  //   name: 'realEstate.commercial.furnished',
  //   label: 'Furnished',
  //   options: REAL_ESTATE_FURNISHED,
  // },
  {
    id: 2,
    name: 'realEstate.commercial.listing',
    label: 'Listing',
    options: REAL_ESTATE_LISTING,
  },
];

// ---------------- ELECTRONICS

const ELECTRONICS_COMPUTER_MAKE = [
  'hp',
  'lenovo',
  'dell',
  'apple',
  'asus',
  'acer',
  'msi',
  'samsung',
  'toshiba',
  'others',
];

const ELECTRONICS_MONITOR_MAKE = [
  'Dell',
  'HP',
  'Asus',
  'Acer',
  'LG',
  'Samsung',
  'BenQ',
  'MSI',
  'ViewSonic',
  'Lenovo',
];

const ELECTRONICS_MONITOR_RESOLUTION = ['1080p', '1440p', '4K', '5K', '8K'];

const ELECTRONICS_MONITOR_DISPLAY = ['LCD', 'LED', 'OLED', 'QLED', 'Plasma'];

const ELECTRONICS_MONITOR_SCREENSIZE = ['15', '22', '23', '24', '25', '27', '28', '29', '32', '34'];

const ELECTRONICS_SOUND_TYPE = ['headphones', 'speakers', 'microphones', 'others'];

const ELECTRONICS_SOUND_BRAND = ['Sony', 'Bose', 'Sennheiser', 'JBL', 'Logitech', 'Audio Technica'];

const ELECTRONICS_SOUND_CONNECTIVITY = ['wired', 'wireless'];

const ELECTRONICS_HOME_TYPE = [
  'refrigerator',
  'washing machine',
  'dishwasher',
  'oven',
  'microwave',
  'vacuum cleaner',
  'air conditioner',
  'heater',
  'fan',
  'others',
];

const ELECTRONICS_COMPUTER_STORAGE = [
  '128GB SSD',
  '256GB SSD',
  '512GB SSD',
  '1TB SSD',
  '2TB SSD',
  '1TB HDD',
  '2TB HDD',
];

const ELECTRONICS_COMPUTER_PROCESSOR = [
  'Intel Core i3',
  'Intel Core i5',
  'Intel Core i7',
  'Intel Core i9',
  'Intel Pentium',
  'Intel Celeron',
  'AMD Ryzen 3',
  'AMD Ryzen 5',
  'AMD Ryzen 7',
  'AMD Ryzen 9',
  'AMD Athlon',
  'Apple M1',
  'Apple M1 Pro',
  'Apple M1 Max',
];

const ELECTRONICS_COMPUTER_RAM = ['2GB', '3GB', '4GB', '8GB', '16GB', '32GB', '64GB'];

export const CAMERA_BRANDS = [
  'Canon',
  'Nikon',
  'Sony',
  'Panasonic',
  'Olympus',
  'Fujifilm',
  'Leica',
  'Pentax',
  'GoPro',
  'Hasselblad',
  'Kodak',
  'Sigma',
  'Ricoh',
  'JVC',
  'Blackmagic Design',
  'RED Digital Cinema',
  'Arri',
  'Phase One',
  'DJI',
  'Samsung',
  'Yi Technology',
  'Insta360',
  'ZEISS',
  'Others',
];

export const computerComponent = [
  {
    id: 1,
    name: 'electronics.computer.make',
    label: 'Make*',
    options: ELECTRONICS_COMPUTER_MAKE,
  },
  {
    id: 2,
    name: 'electronics.computer.storage',
    label: 'Storage',
    options: ELECTRONICS_COMPUTER_STORAGE,
  },
  {
    id: 3,
    name: 'electronics.computer.processor',
    label: 'Processor',
    options: ELECTRONICS_COMPUTER_PROCESSOR,
  },
  {
    id: 4,
    name: 'electronics.computer.ram',
    label: 'RAM',
    options: ELECTRONICS_COMPUTER_RAM,
  },
];

export const monitorComponent = [
  {
    id: 1,
    name: 'electronics.monitor.make',
    label: 'Make*',
    options: ELECTRONICS_MONITOR_MAKE,
  },
  {
    id: 2,
    name: 'electronics.monitor.resolution',
    label: 'Resolution',
    options: ELECTRONICS_MONITOR_RESOLUTION,
  },
  {
    id: 3,
    name: 'electronics.monitor.display',
    label: 'Display',
    options: ELECTRONICS_MONITOR_DISPLAY,
  },
  {
    id: 4,
    name: 'electronics.monitor.size',
    label: 'Screen Size',
    options: ELECTRONICS_MONITOR_SCREENSIZE,
  },
];

export const soundComponent = [
  {
    id: 1,
    name: 'electronics.sound.type',
    label: 'Type*',
    options: ELECTRONICS_SOUND_TYPE,
  },
  {
    id: 2,
    name: 'electronics.sound.brand',
    label: 'Brand',
    options: ELECTRONICS_SOUND_BRAND,
  },
  {
    id: 3,
    name: 'electronics.sound.connectivity',
    label: 'Connectivity',
    options: ELECTRONICS_SOUND_CONNECTIVITY,
  },
];

export const homeComponent = [
  {
    id: 1,
    name: 'electronics.home.type',
    label: 'Type*',
    options: ELECTRONICS_HOME_TYPE,
  },
];

export const cameraComponent = [
  {
    id: 1,
    name: 'electronics.camera.brand',
    label: 'Brand*',
    options: CAMERA_BRANDS,
  },
];

// ---------------- MOBILE AND TABLETS

const MAKE_MOBILE = [
  'Apple',
  'Google',
  'Motorola',
  'OnePlus',
  'Samsung',
  'Cat',
  'CrossCall',
  'Emporia',
  'Fairphone',
  'Nokia',
  'Oppo',
  'Sony',
  'Xiaomi',
  'Others',
];

const TABLET_BRANDS = [
  'Apple',
  'Samsung',
  'Microsoft',
  'Amazon',
  'Huawei',
  'Lenovo',
  'Dell',
  'Asus',
  'HP',
  'Acer',
];

const MOBILE_AND_TABLETS_STORAGE = [
  '2GB',
  '4GB',
  '8GB',
  '16GB',
  '32GB',
  '64GB',
  '128GB',
  '256GB',
  '512GB',
  '1TB',
];

const MOBILE_PHONE_DISPLAY = [
  'LCD',
  'LED',
  'OLED',
  'AMOLED',
  'Super AMOLED',
  'Retina Display',
  'IPS',
  'TFT',
];

const OPERATING_SYSTEMS = ['Android', 'AOSP', 'iOS', 'Other', 'Oxygen', 'OxygenOS'];

export const mobileComponent = [
  {
    id: 1,
    name: 'mobileAndTablets.mobile.make',
    label: 'Make*',
    options: MAKE_MOBILE,
  },
  {
    id: 2,
    name: 'mobileAndTablets.mobile.display',
    label: 'Display',
    options: MOBILE_PHONE_DISPLAY,
  },
  {
    id: 3,
    name: 'mobileAndTablets.mobile.storage',
    label: 'Storage',
    options: MOBILE_AND_TABLETS_STORAGE,
  },
  {
    id: 4,
    name: 'mobileAndTablets.mobile.operatingSystem',
    label: 'Operating system*',
    options: OPERATING_SYSTEMS,
  },
];

export const tabletComponent = [
  {
    id: 1,
    name: 'mobileAndTablets.tablet.make',
    label: 'Make*',
    options: TABLET_BRANDS,
  },
  {
    id: 2,
    name: 'mobileAndTablets.tablet.storage',
    label: 'Storage',
    options: MOBILE_AND_TABLETS_STORAGE,
  },
  {
    id: 3,
    name: 'mobileAndTablets.mobile.operatingSystem',
    label: 'Operating system*',
    options: OPERATING_SYSTEMS,
  },
];

// ---------------- HEALTH AND BEAUTY

export const fragranceComponent = [
  {
    id: 1,
    name: 'healthAndBeauty.fragrance.gender',
    label: 'Gender*',
    options: ['Men', 'Women', 'unisex'],
  },
  {
    id: 2,
    name: 'healthAndBeauty.fragrance.type',
    label: 'Type*',
    options: ['eau de parfum', 'eau de toilette', 'parfum', 'musk'],
  },
];

export const skincareComponent = [
  {
    id: 1,
    name: 'healthAndBeauty.skincare.gender',
    label: 'Gender*',
    options: ['Men', 'Women', 'Unisex'],
  },
];

export const makeupComponent = [
  {
    id: 1,
    name: 'healthAndBeauty.makeup.type',
    label: 'Type*',
    options: ['foundation', 'lipstick', 'mascara', 'others'],
  },
];

export const hairComponent = [
  {
    id: 1,
    name: 'healthAndBeauty.hair.type',
    label: 'Type*',
    options: ['shampoo', 'conditioner', 'hair Oil', 'hair accessories', 'others'],
  },
];

export const oralcareComponent = [
  {
    id: 1,
    name: 'healthAndBeauty.oralcare.type',
    label: 'Type*',
    options: ['Toothpaste', 'Toothbrushes', 'Mouthwash', 'Others'],
  },
];

// ---------------- BABY AND KIDS

export const BABY_AND_KIDS_ACCESSORIES = [
  {
    id: 1,
    name: 'babyAndKids.accessories.type',
    label: 'Type*',
    options: [
      'bags',
      'carriers',
      'car seat',
      'strollers',
      'high chairs',
      'bottles',
      'pacifiers',
      'baby monitors',
      'safety gates',
      'baby gym',
      'play mats',
      'baby walkers',
      'bibs',
      'blankets',
      'others',
    ],
  },
];
export const BABY_AND_KIDS_CLOTHING = [
  {
    id: 1,
    name: 'babyAndKids.clothing.type',
    label: 'Type*',
    options: ['clothing set', 'nightwear', 't-shirt', 'jeans', 'pants', 'dress', 'others'],
  },
  {
    id: 3,
    name: 'babyAndKids.clothing.age',
    label: 'Age*',
    options: Array.from({ length: 18 }, (_, i) => i + 1), // Assuming age is between 1 to 18
  },
  {
    id: 4,
    name: 'babyAndKids.clothing.material',
    label: 'Material',
    options: ['cotton', 'polyester', 'wool'],
  },
];

export const shoesBabyComponent = [
  {
    id: 1,
    name: 'babyAndKids.shoes.type',
    label: 'Type*',
    options: ['sneakers', 'casual', 'sandals', 'others'],
  },
  {
    id: 2,
    name: 'babyAndKids.shoes.size',
    label: 'Size*',
    options: Array.from({ length: 25 }, (_, i) => (i + 16).toString()), // Assuming shoe size between 16 to 40
  },
];

export const childcareComponent = [
  {
    id: 1,
    name: 'babyAndKids.childcare.type',
    label: 'Type*',
    options: [
      'baby food',
      'diapers',
      'baby wipes',
      'baby skincare products',
      'bathing products',
      'baby shampoo',
      'baby lotion',
      'baby powder',
      'others',
    ],
  },
];

export const toysComponent = [
  {
    id: 1,
    name: 'babyAndKids.toys.age',
    label: 'Age*',
    options: [
      '0-3 months',
      '3-6 months',
      '6-9 months',
      '9-12 months',
      '1-3 years',
      '3-5 years',
      '5-7 years',
      '7-10 years',
      '10-14 years',
      'others',
    ],
  },
];

// ---------------- FOOD

const FRUIT_AND_VEGETABLES_TYPES = ['fruits', 'vegetables', 'fresh herbs', 'others'];

export const fruitAndvegetablesComponent = [
  {
    id: 1,
    name: 'food.fruitAndvegetables.type',
    label: 'Type*',
    options: FRUIT_AND_VEGETABLES_TYPES,
  },
];

const DAIRY_AND_EGGS_TYPES = [
  `fresh cow's milk`,
  `fresh camel's milk`,
  'Yoghurt',
  'butter',
  'cheese',
  'eggs',
  'others',
];

export const dairyAndeggsComponent = [
  {
    id: 1,
    name: 'food.dairyAndeggs.type',
    label: 'Type*',
    options: DAIRY_AND_EGGS_TYPES,
  },
];

const BASIC_FOOD_TYPES = [
  'oil',
  'sugarAndSalt',
  'pasta',
  'flour',
  'rice',
  'canned food',
  'spices',
  'others',
];

export const basicFoodComponent = [
  {
    id: 1,
    name: 'food.basicFood.type',
    label: 'Type*',
    options: BASIC_FOOD_TYPES,
  },
];

const DRINKS_TYPES = [
  'freshJuices',
  'soda',
  'tea',
  'coffee',
  'energy drinks',
  'water',
  'milkshakes',
  'others',
];

export const drinksComponent = [
  {
    id: 1,
    name: 'food.drinks.type',
    label: 'Type*',
    options: DRINKS_TYPES,
  },
];

const LIVESTOCK_TYPES = ['cows', 'camels', 'sheeps', 'goats', 'chicken', 'others'];

export const livestockComponent = [
  {
    id: 1,
    name: 'food.livestock.type',
    label: 'Type*',
    options: LIVESTOCK_TYPES,
  },
];

const SNACKS_AND_SWEETS_TYPES = [
  'Chocolates',
  'Candies',
  'Cookies',
  'Cakes',
  'Ice-creams',
  'Chips',
  'Popcorn',
  'Nuts',
  'Dried-Fruits',
  'Others',
];

export const snacksAndsweetsComponent = [
  {
    id: 1,
    name: 'food.snacksAndsweets.type',
    label: 'Type*',
    options: SNACKS_AND_SWEETS_TYPES,
  },
];

const config = {
  fashion: {
    clothing: clothingComponent,
    accessories: accessoriesComponent,
    jewelry: jewelryComponent,
    shoes: shoesComponent,
    bag: bagComponent,
    wedding: weddingComponent,
  },
  vehicle: {
    car: carComponent,
    bus: busComponent,
    motor: motorComponent,
    truck: truckComponent,
  },
  vehicleParts: {
    engine: engineComponent,
    engineOil: engineOilComponent,
    filter: filterComponent,
    brakes: brakesComponent,
    interior: interiorComponent,
    lights: lightsComponent,
    wheels: wheelsComponent,
    tires: tiresComponent,
    gearbox: gearboxComponent,
    suspension: suspensionComponent,
    exhaust: exhaustComponent,
    battery: batteryComponent,
  },
  realEstate: {
    apartment: apartmentComponent,
    house: houseComponent,
    // land: landComponents,
    commercial: commercialComponent,
  },
  electronics: {
    computer: computerComponent,
    monitor: monitorComponent,
    sound: soundComponent,
    camera: cameraComponent,
    home: homeComponent,
  },
  mobileAndTablets: {
    mobile: mobileComponent,
    tablet: tabletComponent,
  },
  healthAndBeauty: {
    fragrance: fragranceComponent,
    skincare: skincareComponent,
    makeup: makeupComponent,
    hair: hairComponent,
    oralcare: oralcareComponent,
  },
  babyAndKids: {
    accessories: accessoriesComponent,
    clothing: clothingComponent,
    childcare: childcareComponent,
    shoes: shoesBabyComponent,
    toys: toysComponent,
  },
  food: {
    fruitAndvegetables: fruitAndvegetablesComponent,
    dairyAndEggs: dairyAndeggsComponent,
    basicFood: basicFoodComponent,
    drinks: drinksComponent,
    livestock: livestockComponent,
    snacksAndsweets: snacksAndsweetsComponent,
  },
};

export default config;
