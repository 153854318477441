import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import Button from '@mui/material/Button';
// routes
import { paths } from 'src/routes/paths';
// component
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function LoginButton({ sx, isAuthenticated }) {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    const currentPath = window.location.pathname + window.location.search;
    navigate(paths.auth.login(`?returnTo=${currentPath}`));
    return () => {};
  };

  return (
    <Button
      onClick={handleLoginClick}
      variant="contained"
      sx={{ mr: 1, pl: 2, pr: 2, ...sx }}
      endIcon={<Iconify icon={isAuthenticated ? 'ic:baseline-plus' : 'memory:login'} />}
    >
      Login
    </Button>
  );
}

LoginButton.propTypes = {
  isAuthenticated: PropTypes.bool,
  sx: PropTypes.object,
};
