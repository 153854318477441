import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
//
import HomePage from 'src/pages/home';
// import { useSelector } from 'react-redux';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { PrivateRoutes } from './private';
// components

// ----------------------------------------------------------------------

export default function Router() {
  // const { isInitialized } = useSelector((state) => state.auth);
  // const { newArrivalsProducts } = useSelector((state) => state.product);

  // const isLoading = !isInitialized && !newArrivalsProducts;

  return useRoutes([
    {
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ path: '/', element: <HomePage /> }],
    },
    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...PrivateRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
