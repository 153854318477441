import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// import Pagination, { paginationClasses } from '@mui/material/Pagination';
//
// import { _products } from 'src/_mock';

//
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import ProductItem from './product-item';
import { ProductItemSkeleton } from './product-skeleton';

// ----------------------------------------------------------------------

export default function ProductList({ products, loading, title, ...other }) {
  const renderSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ProductItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {products?.map((product) => (
        <ProductItem key={product?._id} product={product} />
      ))}
    </>
  );

  return (
    <Container
      sx={{
        py: { xs: 5, md: 5 },
        px: 1,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
          mb: 2,
        }}
      >
        {title}
      </Typography>

      <Box
        gap={1}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        {...other}
      >
        {loading ? renderSkeleton : renderList}
      </Box>
    </Container>
  );
}

ProductList.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.array,
  title: PropTypes.string,
};
