import PropTypes from 'prop-types';
// @mui
// import Collapse from '@mui/material/Collapse';
// import { listClasses } from '@mui/material/List';
// import { listItemTextClasses } from '@mui/material/ListItemText';
// import { listItemButtonClasses } from '@mui/material/ListItemButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
// import { NavSectionVertical } from 'src/components/nav-section';
import { usePathname } from 'src/routes/hooks';
//
import NavItem from './nav-item';

// ----------------------------------------------------------------------
export default function NavList({ item }) {
  const pathname = usePathname();
  const { path, onToggle, theme } = item;

  const externalLink = path?.includes('http');
  const nav = useBoolean();

  const handleClick = () => {
    if (theme) {
      if (onToggle) onToggle();
      return;
    }
    nav.onToggle();
  };

  return (
    <NavItem
      item={item}
      open={nav.value}
      onClick={handleClick}
      active={pathname === path}
      externalLink={externalLink}
    />
  );
}

NavList.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string,
    onToggle: PropTypes.func,
    theme: PropTypes.bool,
  }).isRequired,
};
