const getUrl = (image, size) => {
  const baseUrl = 'https://dy6l8mnlnom4l.cloudfront.net/';
  const rawKey = image.replace(baseUrl, '');
  const decodedKey = decodeURIComponent(rawKey);

  const sizes =
    size !== 'original'
      ? {
          small: { width: 100, height: 100, fit: 'cover' },
          medium: { width: 600, height: 600, fit: 'cover' },
          large: { width: 1920, height: 1080, fit: 'cover' },
        }
      : {};

  const imageRequest = JSON.stringify({
    bucket: 'haraash',
    key: decodedKey,
    edits: { resize: sizes[size] },
  });

  const encodedObject = btoa(imageRequest);

  return encodedObject;
};

export const getImgUrl = (image, size) => {
  const url = getUrl(image, size);

  return `https://dy6l8mnlnom4l.cloudfront.net/${url}`;
};
