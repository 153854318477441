import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';

const BannerBox = styled(Box)(({ theme }) => ({
  width: '468px',
  height: '60px',
  background: 'linear-gradient(to right, #FAAB4C, rgb(122, 122, 122), #5796D0)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[4],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const TextContainer = styled(Box)({
  flex: 1,
  overflow: 'hidden',
});

const StyledButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
}));

export const AdBanner = ({ title, description, ctaText }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mx: 2, mt: 2 }}>
      <BannerBox>
        <TextContainer>
          <Typography
            variant="subtitle2"
            sx={{ color: 'white', fontWeight: 600, lineHeight: 1.2 }}
            noWrap
          >
            {title}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.8)' }} noWrap>
            {description}
          </Typography>
        </TextContainer>
        <StyledButton onClick={handleOpen}>
          <Iconify icon="line-md:question" size={16} sx={{ color: 'white' }} />
        </StyledButton>
      </BannerBox>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: 2,
            maxWidth: '400px',
            mx: 'auto',
            mt: '10%',
          }}
        >
          <Typography variant="h6" gutterBottom>
            More Information
          </Typography>
          <Typography variant="body2">
            Starting January 8th, 2025, your ads will stay live for 60 days, giving you maximum
            visibility and helping you connect with the right buyers. Want to keep your ad online?
            Simply republish it after 60 days and continue reaching your audience!
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

AdBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
};

export default AdBanner;
