export const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/profile.php?id=100089356614000',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: '#',
  },
  {
    value: 'youtube',
    name: 'YouTube',
    icon: 'logos:youtube-icon',
    color: '#FF0000',
    path: 'https://www.youtube.com/@Haraash',
  },

  // {
  //   value: 'x',
  //   name: 'X',
  //   icon: 'fa6-brands:square-x-twitter',
  //   color: '#00AAEC',
  //   // path: 'https://www.x.com/caitlyn.kerluke',
  // },
];
