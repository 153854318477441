import PropTypes from 'prop-types';
// @mui
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// utils
import { fCurrency } from 'src/utils/format-number';
// components
import ProductRating from 'src/components/rating/rating';
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

//
import Typography from '@mui/material/Typography';
import { getImgUrl } from 'src/utils/loadImgsFromS3';

// ----------------------------------------------------------------------

export default function ProductItem({ product }) {
  const {
    _id,
    name,
    cover,
    price,
    isNegotiable,
    available,
    priceSale,
    newLabel,
    saleLabel,
    location: { region, city },
    userId: { reviews, averageRating },
  } = product;

  const linkTo = paths.product.details(_id);

  const renderLabels = (newLabel?.enabled || saleLabel?.enabled) && (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ position: 'absolute', zIndex: 9, top: 16, right: 16 }}
    >
      {newLabel.enabled && (
        <Label variant="filled" color="info">
          {newLabel?.content}
        </Label>
      )}
      {saleLabel.enabled && (
        <Label variant="filled" color="error">
          {saleLabel?.content}
        </Label>
      )}
    </Stack>
  );

  const renderImg = (
    <Box sx={{ position: 'relative', p: 1 }}>
      {!!available && (
        <Fab
          color="warning"
          size="medium"
          className="add-cart-btn"
          sx={{
            right: 16,
            bottom: 16,
            zIndex: 9,
            opacity: 0,
            position: 'absolute',
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Link component={RouterLink} href={linkTo} color="inherit" variant="subtitle2" noWrap>
            <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
              <Iconify icon="mdi:eye" width={24} />
            </Stack>
          </Link>
        </Fab>
      )}

      <Tooltip title={!available && 'Out of stock'} placement="bottom-end">
        <Link component={RouterLink} href={linkTo} color="inherit" variant="subtitle2" noWrap>
          <Image
            alt={name}
            src={getImgUrl(cover, 'medium')}
            ratio="1/1"
            sx={{
              borderRadius: 1.5,
              // ...(!available && {
              //   opacity: 0.48,
              //   filter: 'grayscale(1)',
              // }),
            }}
          />
        </Link>
      </Tooltip>
    </Box>
  );

  const renderContent = (
    <Stack spacing={0} sx={{ p: 3, pt: 2 }}>
      <Link component={RouterLink} href={linkTo} color="inherit" variant="subtitle2" noWrap>
        <Typography
          sx={{
            mx: 'auto',
            maxWidth: 480,
            wordWrap: { sm: 'break-word' }, // Optional, but not necessary for Typography
            whiteSpace: { xs: 'nowrap', sm: 'normal' }, // Allow wrapping for md and above
            overflow: { xs: 'hidden', sm: 'visible' }, // Hide overflow for small screens
            textOverflow: { xs: 'ellipsis', sm: 'clip' }, // Ellipsis on small screens
            fontWeight: 'fontWeightBold',
          }}
          variant="body2"
          line={1}
        >
          {name}
        </Typography>
      </Link>
      <ProductRating
        ratingNumber={Number(averageRating)}
        label={`${reviews?.length} Seller Review`}
      />
      <Typography
        variant="body2"
        line={1}
        sx={{ fontWeight: 'fontWeightLight', color: 'text.secondary' }}
      >
        {`${region}, ${city}`}
      </Typography>

      <Stack direction="row" sx={{ typography: 'subtitle2', mt: 2 }}>
        {isNegotiable ? 'Negotiable' : fCurrency(price)}

        <Box
          component="span"
          sx={{
            ml: 0.5,
            color: 'text.disabled',
            textDecoration: 'line-through',
            fontWeight: 'fontWeightMedium',
          }}
        >
          {priceSale > 0 && fCurrency(priceSale)}
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <Card
      sx={{
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
      }}
    >
      {renderLabels}

      {renderImg}

      {renderContent}
    </Card>
  );
}

ProductItem.propTypes = {
  product: PropTypes.object,
};
